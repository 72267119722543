<template>
  <v-card width="400px">
    <v-card-title>En attente d'approbation</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <p class="success--text">
        Votre compte a bien été créé.
      </p>
      <p>
        Conformément aux consignes de l'ANTS, vos informations doivent être
        vérifiées par notre équipe.
      </p>
      <p>
        Vous recevrez un email lorsque votre compte aura été approuvé. Cela ne
        prend en général que quelques heures.
      </p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn :to="{ name: 'Home' }" exact text>
        <v-icon left>mdi-chevron-left</v-icon>
        Retour
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WaitingApproval',
}
</script>

<style scoped></style>
